


<template>
	<div class="loadingContainer1 w-fit flex gap-1">
		<div class="ball1 size-3 rounded-full"></div>
		<div class="ball2 size-3 rounded-full"></div>
		<div class="ball3 size-3 rounded-full"></div>
		<!-- <div class="ball4 size-3 rounded-full"></div> -->
	</div>
</template>


<style>
.loadingContainer1 div {
	background:#9BC861;
	transform:scale(.2);
}
.loadingContainer1 .ball1 {
	z-index:1;
	animation: grow 2s infinite ease-in-out;
	-moz-animation: grow 2s infinite ease-in-out;
	-webkit-animation: grow 2s infinite ease-in-out;
}
.loadingContainer1 .ball2 {
	animation: grow 2s infinite ease-in-out;
	-moz-animation: grow 2s infinite ease-in-out;
	-webkit-animation: grow 2s infinite ease-in-out;
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}
.loadingContainer1 .ball3 {
	animation: grow 2s infinite ease-in-out;
	-moz-animation: grow 2s infinite ease-in-out;
	-webkit-animation: grow 2s infinite ease-in-out;
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
}
.loadingContainer1 .ball4 {
	animation: grow 2s infinite ease-in-out;
	-moz-animation: grow 2s infinite ease-in-out;
	-webkit-animation: grow 2s infinite ease-in-out;
	-webkit-animation-delay: 0.6s;
	animation-delay: 0.6s;
}

@-moz-keyframes grow {
	0% {-moz-transform: scale(0);background:#72c2c2;}
	25% {-moz-transform: scale(1);background:#9BC861;}
	50% {-moz-transform: scale(.2);background:#E4E451;}
	75% {-moz-transform: scale(1);background:#72c2c2;}
	100% {-moz-transform: scale(.2);background:#9BC861;}
}
@-webkit-keyframes grow {
	0% {-webkit-transform: scale(.2);background:#72c2c2;}
	25% {-webkit-transform: scale(1);background:#9BC861;}
	50% {-webkit-transform: scale(.2);background:#E4E451;}
	75% {-webkit-transform: scale(1);background:#72c2c2;}
	100% {-webkit-transform: scale(.2);background:#9BC861;}
}
@keyframes grow {
	0% {transform: scale(.2);background:#72c2c2;}
	25% {transform: scale(1);background:#9BC861;}
	50% {transform: scale(.2);background:#E4E451;}
	75% {transform: scale(1);background:#72c2c2;}
	100% {transform: scale(.2);background:#9BC861;}
}
</style>
